@import "../../../styles/constants.scss";

.reset-page-wrapper {
  @include login-background-image-stylings;
  display: flex;
  flex-flow: column;
  min-height: 100vh;

  .reset-page-container {
    margin-top: 125px;
    min-height: 600px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      color: $color-white;
      font-size: $font-large-banner-font-size;
      text-align: center;
    }

    .reset-form {
      @include chrome-autofill-behaviour;
      @include login-form-base-stylings;

      .feedback-message {
        color: $color-white;
        margin-top: 2rem;
        margin-bottom: 0px;
      }

      .reset-button {
        @include login-button;
      }

      .instruction-message {
        font-family: $font-body-font-family;
        color: $color-white;
        line-height: $font-default-line-height;
        font-size: 16px;
        text-align: left;

        &.success {
          text-align: center;
        }
      }
    }
  }

  .copyright-wrapper {
    @media screen and (min-height: 768px) {
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media screen and (max-width: $sm-mobile-breakpoint) {
    .reset-page-container {
      margin-top: 75px;

      h1 {
        font-size: 36px;
      }

      .reset-form {
        // Readjust text input widths
        width: 90%;
        min-width: auto;
        max-width: $min-desktop-form-width;
      }
    }
  }
}
