@import "../../styles/constants.scss";

$color-warmer-purple: #8600d6;

.not-found-page-not-logged {
  background: radial-gradient(darken($color-warmer-purple, 3%), $color-black);
  padding: 0px 10px;
  text-align: center;
  color: $color-white;
  min-height: 100vh;
  padding-top: $header-height;

  .not-found-graphic-image {
    max-width: 100%;
  }

  .not-found-header {
    font-size: 32px;
    font-weight: $font-extra-bold-font-weight;
    margin-top: 31px;
    margin-bottom: 20px;
  }

  .not-found-message {
    font-size: $font-large-font-size;
  }

  .go-back-button {
    margin-top: 70px;
    padding-bottom: 30px;

    button {
      @include login-button;
      padding: 16px 46px;
      border: none;
      font-weight: $font-extra-bold-font-weight;
      font-size: $font-large-font-size;
      width: fit-content;
    }
  }

  @media screen and (max-width: $sm-mobile-breakpoint) {
    padding-top: $mobile-header-height;
  }
}
