@import "../../styles/constants.scss";

.copyright-wrapper {
  color: $color-white;
  margin: 2rem 0rem;
  text-align: center;

  .copyright {
    font-size: 12px;

    .copyright-text {
      margin: 5px 10px;
      display: inline-block;

      a {
        color: $color-white;
      }
    }
  }
}
