@import "../../styles/constants.scss";

$color-warmer-purple: #8600d6;

.not-found-page-logged {
  background: -webkit-linear-gradient($color-purple, $color-pink);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  height: 100vh;

  .not-found-content-wrapper {
    text-align: center;
    padding-top: 30vh;

    .code {
      font-size: 128px;
    }

    .not-found {
      font-size: 64px;
    }
  }

  @media screen and (max-width: $md-mobile-breakpoint) {
    .not-found-content-wrapper {
      .code {
        font-size: 96px;
      }

      .not-found {
        font-size: $font-large-banner-font-size;
      }
    }
  }

  @media screen and (max-width: $sm-mobile-breakpoint) {
    .not-found-header {
      font-size: 28px;
    }

    .not-found-content-wrapper {
      .code {
        font-size: 64px;
      }

      .not-found {
        font-size: 32px;
      }
    }
  }
}
