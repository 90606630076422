@import "../../styles/constants.scss";

.drawer-navigation-wrapper {
  .navigation-brand {
    white-space: nowrap;
    text-align: center;

    .image-logo {
      max-width: 38px;
      margin-top: 1.25rem;
    }
  }

  .separator {
    margin: 11rem 0rem 0rem 0rem;
  }

  .chevron {
    transition: all 0.3s;
  }

  .chevron.closed-chevron {
    transform: rotate(-90deg);
  }

  .chevron.rotate-chevron {
    transform: rotate(180deg);
  }

  .account-icon-wrapper {
    position: relative;

    .create-icon {
      position: absolute;
      left: -8px;
      top: -8px;
      color: white;
      height: 60%;
    }
  }

  .sublist-text {
    margin-left: -1rem;
  }

  .user-info-wrapper {
    text-align: center;
    margin: 1rem 0rem 0rem 0rem;
    position: relative;
    top: 0;
    left: 0;
    height: 10rem;

    .avatar {
      height: 5rem;
      border-radius: 50%;
      border-style: solid;
      border-color: $color-white;
      top: 0;
      left: 80px;
      position: absolute;
    }

    .avatar-placeholder {
      position: absolute;
      height: 5rem;
      top: 0px;
      left: 80px;
    }

    .username {
      @include font-body-text;
      text-align: center;
      color: $color-white;
      letter-spacing: 0;
      line-height: 16px;
      margin-top: 82px;
      padding: 1rem 0rem;
      white-space: nowrap;
      width: 100%;
    }

    .account-type {
      display: flex;
      border-radius: 4px;
      justify-content: center;

      .text {
        display: flex;
        padding: 0.5rem 1rem;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 4px;
        white-space: nowrap;

        @include font-body-text;
        color: #ffffff;
        font-size: 11px;
        font-weight: 500;
        letter-spacing: 0.92px;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
