@import "../../styles/constants.scss";

.list-item {
  .list-item-label {
    margin-bottom: 10px;
    color: $color-purple-tinged-grey;
    font-size: $font-large-font-size;
    font-weight: $font-bold-font-weight;
    line-height: $font-default-line-height;
    text-align: center;

    &.active {
      color: $color-white;
    }
  }
}
