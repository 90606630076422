@import "../../styles/constants.scss";

.dashboard-view-page {
  .dashboard-content-container {
    @media (min-width: 600px) {
      padding: 0px 15px 0px calc(72px + 15px);
    }

    @media (max-width: $sm-mobile-breakpoint) {
      padding: 0px 15px 0px calc(64px + 15px);
    }

    .dashboard-content {
      margin: auto;
    }
  }
}
