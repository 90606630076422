@import "../../styles/constants.scss";

.custom-input-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: $color-white;
  margin-top: 2.5rem;

  // Grabs only the label text
  h5 {
    @include white-input-label;
  }

  input {
    &::placeholder {
      color: $color-dark-purple-tinged-grey;
      opacity: 100%;
    }
  }
}

:first-child.custom-input-label {
  margin-top: 0rem;
}

@media screen and (max-width: $sm-mobile-breakpoint) {
  .custom-input-label {
    // Hide the label text
    h5 {
      visibility: hidden;
    }
  }
}
