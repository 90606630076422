/* App colors */
$color-purple: #772edd;
$color-light-purple: #8d52e0;
$color-dark-purple: #292332;
$color-pink: #ed2784;
$color-bright-pink: #ff2384;
$color-light-pink: #d65c95;
$color-darker-grey: #4a4a4a;
$color-dark-grey: #8a8a8a;
$color-dark-purple-tinged-grey: #5f5b65;
$color-purple-tinged-grey: #a6a2ab;
$color-light-grey: #afafaf;
$color-lighter-grey: #f8f8f8;
$color-background-grey: #edebf0;
$color-divider-grey: #cfcfcf;
$color-light-black: #363537;
$color-off-white: #faf9fc;
$color-white: #ffffff;
$color-black: #000000;
$color-positive: #42a14f;
$color-negative: #e0495b;

/* App mobile breakpoints */
$sm-mobile-breakpoint: 599px;
$md-mobile-breakpoint: 899px;

/* App font styles */
$font-banner-font-size: 60px;

$font-default-font-size: 14px;
$font-large-font-size: 16px;
$font-large-banner-font-size: 48px;

$font-default-font-weight: 400;
$font-bold-font-weight: 500;
$font-extra-bold-font-weight: 700;

$font-default-line-height: 28px;

/* Header font style */
$font-header-font-family: Montserrat;
$font-header-font-size: 10px;
$font-header-font-style: normal;
$font-header-font-weight: 600;
$font-header-letter-spacing: 0.5px;
$font-header-line-height: normal;
$font-header-text-align: left;

/* Header height styles */
$header-height: 92px;
$mobile-header-height: 84px;

@mixin font-header-text() {
  font-size: $font-header-font-size;
  line-height: $font-header-line-height;
  text-align: $font-header-text-align;
  letter-spacing: $font-header-letter-spacing;
  font-style: $font-header-font-style;
  font-weight: $font-header-font-weight;
  font-family: $font-header-font-family;
}

/* Body font style */
$font-body-font-family: Roboto;
$font-body-font-size: 14px;
$font-body-font-style: normal;
$font-body-font-weight: 400;
$font-body-letter-spacing: 0.7px;
$font-body-line-height: normal;
$font-body-text-align: left;

@mixin font-body-text() {
  font-size: $font-body-font-size;
  line-height: $font-body-line-height;
  text-align: $font-body-text-align;
  letter-spacing: $font-body-letter-spacing;
  font-style: $font-body-font-style;
  font-weight: $font-body-font-weight;
  font-family: $font-body-font-family;
}

/* Small font style */
$font-small-font-family: Roboto;
$font-small-font-size: 12px;
$font-small-font-style: normal;
$font-small-font-weight: 400;
$font-small-letter-spacing: 0.6px;
$font-small-line-height: normal;
$font-small-text-align: left;

/* Centered form widths */
$min-desktop-form-width: 350px;
$max-desktop-form-width: 490px;

@mixin font-small-text() {
  font-size: $font-small-font-size;
  line-height: $font-small-line-height;
  text-align: $font-small-text-align;
  letter-spacing: $font-small-letter-spacing;
  font-style: $font-small-font-style;
  font-weight: $font-small-font-weight;
  font-family: $font-small-font-family;
}

@mixin region-filter-text() {
  font-family: $font-body-font-family;
  font-size: $font-large-font-size;
  color: $color-light-black;
}

@mixin link {
  color: $color-purple;
  text-decoration: none;

  &:visited {
    color: $color-purple;
  }
}

@mixin marketing-banner-font {
  font-size: $font-banner-font-size;
  font-weight: bold;

  @media screen and (max-width: 959px) {
    font-size: $font-large-banner-font-size;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
  }

  @media screen and (max-width: 375px) {
    font-size: 26px;
  }

  @media screen and (max-width: 320px) {
    font-size: 22px;
  }
}

/* Component styles */
$component-default-border-radius: 8px;

/* Image styles */
@mixin round-image {
  border-radius: 50%;
}

@mixin login-background-image-stylings {
  // First value affects the first background image
  // Second value affects the second background image
  background-repeat: no-repeat, no-repeat;
  background-size: auto 450px, cover;
  background-position: bottom, center;

  @media screen and (min-width: 2000px) {
    // Ensures the background image always covers the entire screen's width
    // 110% required due to diagonal edge
    background-size: 110% 450px, cover;
  }
}

/* Button styles */
$button-default-border-radius: 8px;
$button-primary-box-shadow: 0 10px 40px 0 rgba(237, 39, 132, 0.3);

@mixin cta-button {
  border-radius: $button-default-border-radius;
  font-weight: $font-default-font-weight;
  padding: 12px 20px;
}

@mixin shadowed-cta-button {
  @include cta-button;
  box-shadow: $button-primary-box-shadow;
}

@mixin login-button {
  @include cta-button;
  width: 100%;
  text-transform: none;
  margin-top: 3rem;
  color: $color-white;
  background-color: $color-bright-pink;
}

/* Input styles */
@mixin chrome-autofill-behaviour {
  :-webkit-autofill {
    // Delays the autofill background change for a year
    transition: all 1s 31536000s;
    transition-property: color, background-color;
  }
}

@mixin white-input-label {
  margin-top: 0px;
  margin-bottom: 8px;
  color: $color-off-white;
  font-weight: $font-extra-bold-font-weight;
  font-size: $font-large-font-size;
  text-align: left;
}

/* Form Styles */
@mixin login-form-base-stylings {
  padding: 2rem 0px;
  width: 50%;
  max-width: $max-desktop-form-width;
  min-width: $min-desktop-form-width;
}

/* Export variables needed in JS code */
:export {
  colorWhite: $color-white;
  colorDarkPurple: $color-dark-purple;
  colorPurpleTingedGrey: $color-purple-tinged-grey;
  colorDarkPurpleTingedGrey: $color-dark-purple-tinged-grey;
  headerHeight: $header-height;
  mobileHeaderHeight: $mobile-header-height;
  smMobileBreakpoint: $sm-mobile-breakpoint;
  mdMobileBreakpoint: $md-mobile-breakpoint;
}
