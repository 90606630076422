@import "../../styles/constants.scss";

.header-navigation-link {
  color: $color-purple-tinged-grey;
  font-size: $font-large-font-size;
  font-weight: $font-header-font-weight;
  transition: all ease 0.4s;
  line-height: $font-default-line-height;
  letter-spacing: 0px;

  &.active {
    color: $color-white;
  }

  &:hover {
    color: $color-white;
  }
}
