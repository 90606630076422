@import "../../../styles/constants.scss";

.register-page-wrapper {
  @include login-background-image-stylings;
  display: flex;
  flex-flow: column;
  align-items: center;
  min-height: 100vh;
  height: 100%;
  // Keep copyright at bottom of the page
  // Regardless of form height
  justify-content: space-between;

  .register-page-container {
    margin-top: 125px;
    min-height: 600px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      color: $color-white;
      font-size: $font-large-banner-font-size;
      text-align: center;
    }

    .instruction-message {
      font-family: $font-body-font-family;
      color: $color-white;
      line-height: $font-default-line-height;
      font-size: 16px;
      text-align: center;
    }

    .register-button {
      @include login-button;
    }

    .register-form {
      @include chrome-autofill-behaviour;
      @include login-form-base-stylings;
      padding-top: 0px;
      padding-bottom: 4rem;

      .feedback-message {
        color: $color-white;
        margin-top: 2rem;
        margin-bottom: 0px;
      }

      .role-select-label {
        @include white-input-label;
        margin-top: 2.5rem;
      }

      .role-select {
        background: $color-white;
        color: $color-dark-purple-tinged-grey;
        text-align: left;
        padding: 11.5px 14px;
        border-radius: 4px;
        border: 1px solid $color-purple-tinged-grey;
        width: 100%;

        .MuiSelect-icon {
          // Keep the select's arrow icon from the edge
          margin-right: 10px;
        }

        // Cover weird MUI behaviour on Chrome
        .MuiSelect-selectMenu {
          background-color: inherit;
        }
      }
    }
  }

  @media screen and (max-width: $sm-mobile-breakpoint) {
    .register-page-container {
      margin-top: 75px;

      h1 {
        font-size: 36px;
      }

      .register-form {
        // Readjust text input widths
        width: 90%;
        min-width: auto;
        max-width: $min-desktop-form-width;
      }
    }
  }
}
