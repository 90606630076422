@import "../../styles/constants.scss";

.navigation-wrapper {
  justify-content: center;
  touch-action: none;
  min-height: $header-height;
  font-family: $font-body-font-family;

  @media screen and (max-width: 959px) {
    min-height: $mobile-header-height;
  }

  .navigation-brand {
    white-space: nowrap;

    .beatdapp-icon {
      width: 40px;
      vertical-align: middle;

      @media screen and (max-width: 300px) {
        display: none;
      }
    }

    .beatdapp-text {
      margin-left: 0.2rem;
      width: 106px;
      vertical-align: middle;
    }
  }

  .navigation-buttons {
    white-space: nowrap;
    display: flex;
    align-items: center;

    .navigation-button {
      display: inline-block;

      .request-demo-button {
        @include cta-button;
        font-size: $font-large-font-size;
        font-weight: $font-header-font-weight;
        padding: 8px 16px;
        text-transform: none;
        background-color: $color-bright-pink;
      }
    }
  }

  .drawer-menu-icon {
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    height: 30px;
    width: 30px;
    margin-top: 5px;
    margin-right: 20px;
    transition: all ease 0.2s;
    cursor: pointer;
  }
}
